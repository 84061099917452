<!--
  图表组件DashboardCard：统计数字图表
-->
<template>
  <div class="content" :style="{ color: colorValue }" @click="clickNumber">
    <div>
      {{ statisticsValue }}<span>{{ unitLabel }}</span>
    </div>
  </div>
</template>

<script>
import { units } from "@/config/reportDataType/unitData";

export default {
  props: {
    statisticsValue: {
      // 统计数字
      type: String,
      default: "",
    },
    unit: {
      // 单位
      type: String,
      default: "",
    },
    colorValue: {
      // 色值
      type: String,
      default: "",
    },
  },
  data() {
    return {
      unitLabel: "", // 单位label
    };
  },
  watch: {
    // 监听unit，匹配对应的label
    unit: {
      handler() {
        // 整数单位不需要显示
        if (this.unit === "integral") {
          this.unitLabel = "";
        } else {
          this.unitLabel = units().find(
            (item) => item.value === this.unit
          ).label;
        }
      },
      immediate: true,
    },
  },
  methods: {
    // 点击数值
    clickNumber() {
      this.$emit("clickNumber");
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 110px;
  // font-weight: 500;
  font-size: 4em;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  cursor: pointer;
  div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 48px;
    span {
      font-size: 24px;
    }
  }
}
</style>