<!--
 * @Author       : liyb
 * @Date         : 2021-10-30 11:16:25
 * @LastEditors: pengyd
 * @LastEditTime: 2021-11-05 13:09:34
 * @Description  : 仪表板图表-25--type: "digital", // 数字图
 * @FilePath: \lightning-web\src\views\dashboardObject\dashboardIndex\components\DigitalChart.vue
-->
<template>
  <div class="digitalMap">
    <div class="nowMonth" @click="clickNumber">
      <div class="now-date">
        {{ showNowDatetype(chartInfo.onegroupdatetype) }}:
      </div>
      <div class="now-data">
        {{ changeMicrometerLevel(currentData) }}
        <span> {{ unitLabel(chartInfo.unit) }}</span>
      </div>
      <div class="greenDiv" v-if="growthRate.indexOf('-') == -1"></div>
      <div v-else class="redDiv"></div>
      <div :class="growthRate.indexOf('-') == -1 ? '' : 'redFont'">
        {{ growthRate }}
      </div>
    </div>
    <div class="MonthClass" @click="clickNumber">
      <div class="last-date">
        {{ showLastDatetype(chartInfo.onegroupdatetype) }}:
      </div>
      <div class="last-data">
        {{ changeMicrometerLevel(lastData) }}
        <span> {{ unitLabel(chartInfo.unit) }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { units } from "@/config/reportDataType/unitData";
export default {
  name: "digital-chart",
  data() {
    return {};
  },
  props: {
    //当前数据，比如本月
    currentData: {
      type: String,
      default: "",
    },
    //上一数据，比如上月
    lastData: {
      type: String,
      default: "",
    },
    // 增长率
    growthRate: {
      type: String,
      default: "",
    },
    // 图表基本信息
    chartInfo: {
      type: Object,
      default: ()=>({}),
    },
  },
  computed: {
    // 根据图表类型展示图表
    showLastDatetype: function () {
      return (item) => {
        if (item === "day") {
          return this.$i18n.t("label.brief.lastweek"); //上周
        } else if (item === "month") {
          return this.$i18n.t("label.event.lastmonth"); //上月
        } else if (item === "year") {
          return this.$i18n.t("front-lightning-module-v1-dashborad-last-year"); //去年
        }
      };
    },
    unitLabel: function () {
      return (item) => {
        if (item === "integral") {
          return "";
        } else {
          let labelName = units().find((item1) => item1.value === item).label;
          return labelName;
        }
      };
    },
    showNowDatetype: function () {
      return (item) => {
        if (item === "day") {
          return this.$i18n.t("label.brief.thisweek"); //本周
        } else if (item === "month") {
          return this.$i18n.t("label.brief.thismonth"); //本月
        } else if (item === "year") {
          return this.$i18n.t("this.year"); //本年
        }
      };
    },
  },
  mounted() {},
  methods: {
    // 点击数值
    clickNumber() {
      this.$emit("clickNumber");
    },
    // 将数值转换为千分位
    changeMicrometerLevel(num) {
      if (num) {
        var parts = num.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
      }
    },
  },
};
</script>

<style lang='scss' scoped>
// 数字图（环比）样式
.digitalMap {
  width: 100%;
  height: 90%;
  line-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .now-data {
    padding: 0 20px 0 10px;
    color: #333948;
    font-size: 30px;
    font-weight: bold;
  }
  .last-data {
    padding: 0 20px 0 10px;
    color: #6c7792;
    font-size: 20px;
  }
  .now-data span,
  .last-data span {
    color: #6c7792;
    font-size: 14px;
  }
  .now-date,
  .last-date {
    padding: 0 10px;
    color: #6c7792;
    font-size: 20px;
  }
  .nowMonth {
    display: flex;
    .redDiv {
      width: 0px;
      height: 0px;
      margin-top: 12px;
      border: 18px solid #DA4E2E;
      border-bottom: 10px solid transparent;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
    .greenDiv {
      width: 0;
      height: 0;
      margin-top: 10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 18px solid #56ab53;
    }
    .redFont {
      color: #DA4E2E!important;
    }

    div {
      &:last-of-type {
        padding: 0 10px;
        color: #56ab53;
        font-size: 18px;
      }
    }
  }
  .MonthClass {
    color: #6c7792;
    font-size: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>