<!--
 * @Author       : liyb
 * @Date         : 2021-10-30 11:19:09
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-02-18 18:29:50
 * @Description  : 仪表板图表-23--type: "sequential", // 同环比图
 * @FilePath: \lightning-web\src\views\dashboardObject\dashboardIndex\components\SequentialChart.vue
-->
<template>
  <div>
    <el-table
      v-loading="loading"
      :data="listReportDataLineAsc"
      border
      style="margin-top: 5px"
      :cell-style="rowClass"
      :header-cell-style="headClass"
      height="calc(100 % - 22px)"
      @cell-click.self="$emit('clickTable')"
    >
      <!-- 日期字段 -->
      <el-table-column
        :prop="timeApi"
        class-name="data-left"
        :label="timeLabel"
        min-width="100"
      >
      </el-table-column>
      <!-- 数值和金额字段 -->
      <el-table-column :prop="valueApi" :label="valueLabel" min-width="95">
      </el-table-column>
      <!-- 环比 -->
      <el-table-column
        prop="monthRatio"
        min-width="110"
        :label="$t('label_ring_ratio')"
      >
      </el-table-column>
      <!-- 去年同期-->
      <el-table-column
        prop="lastYearSum"
        min-width="80"
        :label="`${valueLabel}${$t('label_report_last_year_same')}`"
      ></el-table-column>
      <!-- 去年同比 -->
      <el-table-column
        prop="yearRatio"
        min-width="80"
        :label="$t('label_report_year_last_year')"
      ></el-table-column>
    </el-table>
  </div>
</template>

<script>
import * as ReportObjectApi from "../../api.js"; // 请求api

export default {
  name: "sequential-chart",
  data() {
    return {
      listReportDataLineAsc: [], // 表格行数据
      timeApi: "", // 时间字段的apiname
      timeLabel: "", // 时间字段的名称
      valueApi: "", // 统计字段的apiname
      valueLabel: "", // 统计字段的名称
      loading: false, //loading
    };
  },
  props: {
    // 报表id
    sequentialChartId: {
      type: String,
      default: "",
    },
  },
  created() {},
  mounted() {
    // 重新获取同环比报表数据
    this.$bus.$on("againGetReportDataByDetail", this.ebFn);
    this.loading = true;
    this.getReportDataByDetail();
  },
  destroyed() {
    this.$bus.$off("againGetReportDataByDetail", this.ebFn);
  },
  methods: {
    ebFn(){
      this.loading = true;
      this.getReportDataByDetail();
    },
    // 根据报表id获取同环比报表的数据
    async getReportDataByDetail() {
      let params = {
        id: this.sequentialChartId,
      };
      let { data } = await ReportObjectApi.getReportDataByDetail(params);
      if (data.listReportDataLineAsc) {
        // 行数据
        this.listReportDataLineAsc = data.listReportDataLineAsc;
        // 时间类型字段
        let groupList = data.reportInfo.groupList;
        this.timeApi = groupList[0].schemefield_name_alias;
        this.timeLabel = groupList[0].labelName;
        // 统计类型字段，先根据gatherfieldname在gatherFieldList中找到该条数据
        let gatherfieldname = data.reportInfo.gatherfieldname;
        let gatherFieldList = data.reportInfo.gatherFieldList;
        let valueData = gatherFieldList.find(
          (item) => item.id === gatherfieldname
        );
        // 赋值
        this.valueApi = valueData.schemefield_name_alias;
        this.valueLabel = valueData.labelName;
        this.loading = false;
        this.$emit("getloading");
      } else {
        this.loading = false;
      }
    },
    rowClass() {
      return "text-align: right; line-height: 30px;padding:5px;";
    },
    headClass() {
      return "text-align: left;background:#788EE0;color:#FFF; height: 30px;padding:0;line-height: 30px;";
    },
  },
};
</script>

<style lang='scss' scoped>
div {
  width: 100%;
  height: 100%;
}
::v-deep .el-table {
  overflow: auto;
}
::v-deep .data-left {
  text-align: left !important;
}
</style>